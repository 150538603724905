<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $t("account") }}</span>
      </h1>
      <b-button
        type="is-primary"
        icon-left="pencil"
        tag="router-link"
        :to="{ name: 'AccountEdit' }"
      >
        {{ $t("edit") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <table class="table details-table">
          <tr>
            <th>{{ $t("name") }}</th>
            <td>{{ user.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("email") }}</th>
            <td>
              <a :href="`mailto:${user.emailAddress}`">
                {{ user.emailAddress }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $t("phone") }}</th>
            <td>
              <a :href="`tel:${user.phoneNumber}`">
                {{ user.phoneNumber }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("language", 1) }}</th>
            <td>{{ user.language.isoName }}</td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $tc("role") }}</th>
            <td>{{ user.roles | highestUserRole }}</td>
          </tr>
          <tr v-if="!userHasRoleSuperAdmin">
            <th>{{ $t("role_company") }}</th>
            <td>{{ companyUser.roles | highestCompanyRole }}</td>
          </tr>
          <tr>
            <th>{{ $t("password") }}</th>
            <td>
              <b-button icon-left="email" class="mt-1" @click="resetPassword">
                {{ $t("password_reset") }}
              </b-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Account",
  data() {
    return {
      loading: true,
      user: null,
      companyUser: null,
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
    ...mapState({
      authUser: (state) => state.auth.user,
      entity: (state) => state.auth.entity,
    }),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getUser", "resetPasswordRequest"]),
    load() {
      this.getUser(this.authUser.id).then((user) => {
        this.user = user;
        this.companyUser = this.user.companies.find(
          (cu) => cu.company === this.entity["@id"]
        );
        this.loading = false;
      });
    },
    resetPassword() {
      this.loading = true;

      this.resetPasswordRequest({ emailAddress: this.user.emailAddress })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("password_reset_sent"),
            type: "is-primary",
          });
        })
        .catch((error) => {
          if (error.message === "TOO_MANY_ATTEMPTS") {
            this.$buefy.toast.open({
              message: this.$t("too_many_attempts"),
              type: "is-danger",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
